<template>
  <v-snackbar
    v-if="configuracao"
    class="toast"
    :value="configuracao.visivel"
    :top="true"
    :timeout="configuracao.tempoLimite"
    max-width="712px"
    :transition="transicao"
    outlined
    :color="iconeCor"
    @input="fecharToast"
  >
    <v-icon :color="iconeCor" class="mr-2">
      {{ icone }}
    </v-icon>

    <span class="toast__message">
      {{ configuracao.mensagem }}
    </span>

    <template v-if="configuracao.podeFechar" v-slot:action="{ attrs }">
      <v-btn icon :color="iconeCor" v-bind="attrs" @click="fecharToast">
        <v-icon> $closeCircle </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Toast',
  computed: {
    configuracao: function () {
      let dados = this.$store.state.Layout.dadosToast;
      if (dados == null) dados = {};
      if (!dados.tipo) dados.tipo = 'error';
      if (!dados.mensagem)
        dados.mensagem = dados.visivel ? this.$t('geral.erro') : '';
      if (!dados.tempoLimite) dados.tempoLimite = 5000;
      if (dados.podeFechar == null) dados.podeFechar = true;
      if (dados.fecharAutomatico == null) dados.fecharAutomatico = true;

      return dados;
    },
    icone() {
      if (!this.configuracao)
        return this.$vuetify.icons.values.closeCircleOutline;

      switch (this.configuracao.tipo) {
        case 'success':
          return this.$vuetify.icons.values.successCircleOutline;
        case 'info':
          return this.$vuetify.icons.values.infoCircleOutline;
        case 'warning':
          return this.$vuetify.icons.values.alertWarningtOutline;
        default:
          return this.$vuetify.icons.values.closeCircleOutline;
      }
    },
    iconeCor() {
      if (!this.configuracao) return 'danger';
      return this.configuracao.tipo;
    },
    transicao() {
      return this.configuracao.visivel
        ? 'scroll-y-transition'
        : 'scroll-y-reverse-transition';
    },
    tempoLimite() {
      return this.configuracao.fecharAutomatico ? this.countDown : '-1';
    },
  },
  methods: {
    fecharToast() {
      this.$store.dispatch('Layout/fecharToast');
    },
  },
};
</script>
